<template>
  <v-container fluid fill-height style="position:relative">
    <v-layout justify-center>
      <v-flex xs12 sm8 md3>
          <v-card class="elevation-12">
            <SystemName />
            <v-form ref="form" @submit.prevent="onSubmit">
              <v-card-text>
                <div style="min-height: 60px;" v-if="alertError">
                  <v-alert
                    class="alert-default"
                    transition="scale-transition"
                    type="error"
                    :value="alertError !== null"
                    >{{ alertError }}</v-alert
                  >
                </div>
                <v-text-field
                  name="login"
                  class="mx-2"
                  v-model="email"
                  color="#13ace0"
                  label="ログインID"
                  type="email"
                  :rules="rules.rulesEmail"
                  autofocus
                ></v-text-field>
                <v-text-field
                  class="mx-2 mt-2"
                  id="password"
                  color="#13ace0"
                  name="password"
                  v-model="password"
                  :rules="rules.rulesPassword"
                  label="パスワード"
                  type="password"
                ></v-text-field>
              </v-card-text>
              <div style="text-align:center">
                <a style="text-decoration: none; color: #13ace0" href="reset-password-staff"
                  >パスワード忘れ</a
                >
              </div>
              <v-checkbox
                v-model="checkbox"
                :disabled="email === '' || password === ''"
                style="width:180px; margin:auto"
                class="mt-4"
                color="#000000"
                label="ログインIDを保存する"
              ></v-checkbox>
              <v-card-text>
                <v-btn width="100%" type="submit" style="height: 50px; color: #FFFFFF" color="#13ace0">CRMログイン</v-btn>
              </v-card-text>
            </v-form>
          </v-card>
      </v-flex>
    </v-layout>
  </v-container>
  <!-- </div> -->
</template>

<script>
import gql from 'graphql-tag';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { mapMutations, mapGetters } from 'vuex';
import { isEmailValid, checkValidatePassword } from "@/utils/validate.js";
import SystemName from './SystemName.vue'

export default {
  components: { SystemName },
  data() {
    return {
      strFrom: '',
      nextPath: '',
      path:this.$route.name,
      flag: false,
      checkbox: window.localStorage.getItem('rememberID'),
      status: '',
      email: this.$store.state.user.email,
      password: '',
      token: '',
      rules: {
        rulesPassword:[
          v => !!v || '必須項目です。',
          v => !v || checkValidatePassword(v) || 'パスワードは8文字以上で、大文字・小文字・数字・記号のうち2種類以上使用する必要があります。'
        ],
        rulesEmail: [
          v => !!v || '必須項目です。',
          v =>
            !v ||
            isEmailValid(v) ||
            this.$t('rules.emailIsInvalid'),
        ],
        // required: [v => !!v || '必須項目です。'],
      },
    };
  },
  created () {
    const routerCurrent = this.$router.currentRoute
    if (routerCurrent.params && routerCurrent.params.strFrom) {
      this.strFrom = routerCurrent.params.strFrom
    }

    if (routerCurrent.params && routerCurrent.params.nextPath) {
      this.nextPath = routerCurrent.params.nextPath
    }
  },
  mounted(){
    document.title = 'Tokyu Vacations Operation System'
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),

    async onSubmit() {
      if (this.$refs.form.validate()) {
        if(this.checkbox){
          localStorage.setItem("rememberID", true);
          localStorage.setItem("email", this.email);
        }else{
          localStorage.removeItem("rememberID");
        }
        const formData = {
          email: this.email,
          password: this.password,
        };
        if (await this.getToken(formData)) {
          if (this.status === 'ChangePassword') {
            this.$router.push({ path: '/change-password' });
          } else if (this.nextPath.length > 0){
            this.$router.push({ name: 'confirmMfaStaff', params: { strFrom: this.nextPath } })
          }
          else {
            this.$router.push({ name: 'confirmMfaStaff', params: { strFrom: this.strFrom } })
          }
        }
      }
    },

    async getToken(formData) {
      const query = `
            mutation login($password: String!, $email: String!) {
              requestMFAStaff(password: $password, email: $email) {
                token
                validUntil
                type
              }
            }
          `
      const variables = {
            password: formData.password,
            email: formData.email,
          }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then(data => {
          if (
            this.$store.dispatch('setTokenAndEmail', {
              email: formData.email,
              token: data.data.requestMFAStaff.token,
            })
          ) {
            if (data.data.requestMFAStaff.type === 'ChangePassword') {
              this.$router.push({ path: '/change-password' });
            }else{
              this.status = data.data.requestMFAStaff.type;
              this.flag = true;
            }
          }
          this.setAlertError(null)
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
      if (this.flag) return true;
      else return false;
    },
  },

  watch: {
    doneTodosCount (value) {
      // if (value !== null) {
      //   setTimeout(() => {
      //     this.setAlertSuccess(null)
      //   }, 2000)
      // }
    },
    doneTodosCountError (value) {
      // if (value !== null) {
      //   setTimeout(() => {
      //     this.setAlertError(null)
      //   }, 2000)
      // }
    }
  },
  computed: {
    ...mapGetters(['alertSuccess', 'alertError', 'errorDialog', 'loadingOverlay']),
    doneTodosCount () {
      return this.alertSuccess
    },
    doneTodosCountError () {
      return this.alertError
    }
  },

};
</script>

<style lang="scss" scoped>
::v-deep {
  .message-transition-enter-to {
    line-height: 17px;
  }
}
.v-form .v-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.v-card {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
